/* Introduction page
------------------------------------------------------------------- */

body.introduction img {
	border-radius: 50%;
}
body.introduction #content {
	min-height: 500px;
}
.introduction-text p {
	margin: 0 auto 1em auto;
}

