@charset "utf-8";

// Import partials from `sass_dir` (defaults to `_sass`)
// To change settings open the partials in the according folders.
// You better leave this file as is.

@import "00_functions/00_functions";

//  Set color scheme in _config.yml
@import "01_colors/_color_scheme_simplicity.scss";

@import "02_settings/00_settings";

@import "02_settings/00_normalize";


//     ______                                         __
//    /_  __/_  ______  ____  ____ __________ _____  / /_  __  __
//     / / / / / / __ \/ __ \/ __ `/ ___/ __ `/ __ \/ __ \/ / / /
//    / / / /_/ / /_/ / /_/ / /_/ / /  / /_/ / /_/ / / / / /_/ /
//   /_/  \__, / .___/\____/\__, /_/   \__,_/ .___/_/ /_/\__, /
//       /____/_/          /____/          /_/          /____/

/* Font Families */
$font-family-serif:         "Liberation Serif", Georgia, "Times New Roman", serif;
$font-family-sans-serif:    "Liberation Sans", "Source Sans Pro", "Neue Helvetica", Arial, sans-serif;
$base-font-family:          "Liberation Sans", "Source Sans Pro", "Neue Helvetica", Arial, sans-serif;
$font-family-monospace:     "DejaVu Sans Mono", "Source Code Pro", "Courier New", Monaco, monospace;
$header-font-family:        "Liberation Sans", "Source Sans Pro", "Neue Helvetica", Arial, sans-serif;

/* Line-height */
$base-line-height: 1.5;

/* Font Weight */
$base-font-weight: normal;
$font-weight-normal: normal;
$font-weight-bold: bold;
/* added to make e. g. h6 more visible, see also _sass/04_typography/_general_typography.scss 25.06.2020 --rw: */
$font-weight-bolder: bolder;
/* added to make some fonts appear lighter, see also _sass/04_typography/_general_typography.scss 25.06.2020 --rw: */
$font-weight-lighter: lighter;

@import "04_typography/typography_modular_scale_1em.scss";
@import "04_typography/general_typography.scss";
@import "04_typography/typography_article.scss";
@import "04_typography/typography_media_queries.scss";
@import "04_typography/typography_css_elements.scss";
@import "04_typography/syntax_highlighting.scss";
@import "04_typography/links";
/* Added, seems to have been missing 29.08.2018, here: 06.07.2020 --rw: */
@import "04_typography/_footnotes.scss";






@import "05_layout/00_layout";
