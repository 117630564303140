/* LAYOUT
------------------------------------------------------------------- */

// Don't hide scrollbar – Makes the logo jump
html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
}

body {
    background-color: $background-color;
}

// IMPORTANT FOR FOUNDATION GRID
html,
body {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit; }


@import "grid";
@import "navigation";

@import "masthead";
@import "logo";
@import "search";
@import "content";
@import "meta";
@import "pagination";
@import "footer";
@import "jump_to_top";
@import "layout_media_queries.scss";
@import "alerts.scss";
@import "toc.scss";
@import "buttons.scss";
@import "_responsive_video.scss";
@import "layout_elements.scss";
@import "inline_list.scss";
@import "layout_introduction_page";
@import "social_media_icons.scss";
