/* Color Variables
-------------------------------------------------------------------

- Corporate Identity Colorpalette
- Alerts
- Grey Scale
- Basics
- Code

*/


/* Corporate Identity Colorpalette
------------------------------------------------------------------- */

/* $ci-1: #900055;		// is used as the primary branding color – for example for links.
Changed 28.06.2018 --rw: */
// Falu red:  
// $ci-1: #801818;
/* From digi-oek.ch current colour 01.12.2018 --rw: */
$ci-1: #337ab7; 
/* ci-2 is used e. g. in #tnav a:hover. Since ci-1 is changed, used original ci-1 instead 20.07.2018 --rw
$ci-2: #d4007a;		// is used as the secondary branding color.  */
// $ci-2: #900055;		// is used as the secondary branding color.
/* Hover over top logo colour -> Complementary Color of $ci-1 (not hovered)  */
$ci-2: #b77033;
$ci-3: #fabb00;
$ci-4: #f5b6c9;
/* Added 13.07.2018 --rw: 
Dark coral cd5b45: 
$ci-5: #cd5b45; 
Terra cotta e2725b: 
added specifically for tag cloud
$ci-5: #e2725b;  */
/* For digi-oek.ch: currently complementary color of ci-1, 
   i. e. = $ci-2  01.12.2018 --rw: */ 
$ci-5: $ci-2;
/* Add ci-6: Background-color for #tnav a:hover was originally ci-1, a bit too dark, try Dark coral cd5b45: 
   This is a very prominent colour since hovering over the site title has this background.  20.07.2018 --rw 
$ci-6: #cd5b45;  */
/* From digi-oek.ch current colour 01.12.2018 --rw: */
$ci-6: #abcdef;



/* Alerts
------------------------------------------------------------------- */

$alert-color:   #aa0132;
/* Test 27.04.2018 --rw:  
$alert-color:   #111; */
$success-color: #6a9519;
$warning-color: #e05a10;
$info-color:    #0085ba;



/* Grey Scale
------------------------------------------------------------------- */

//$grey-color:       #828282;
/* A little bit lighter dark grey colour 01.12.2018 --rw: */
$grey-color:       #858585;
// $grey-color-light: #E4E4E4;
/* Even lighter grey colour 01.12.2018 --rw: */
$grey-color-light: #e7e7e7;
$grey-color-dark:  #4b4b4d;



/* Basics
------------------------------------------------------------------- */

$text-color:       		#111;
$background-color: 		#fefefe;
// Following Color used for .toc, a:visted;
$background-light-color: 	$grey-color-light;
/* Changed for digi-oek.ch, but lighter background color 
	01.12.2018 --rw: 
$line-color:       			$grey-color-light; */ 
$line-color:       			$grey-color;
/* New for digi-oek.ch, background color for certain backgrounds like
	/_sass/05_layout/_navigation.scss > .tnav-children > background 
	01.12.2018 --rw: */ 
$bgcolor:       			$grey-color-light;



/* Topbar Navigation
------------------------------------------------------------------- */

$topbar-navigation-background-color:    $background-color;
$topbar-navigation-border-bottom:       1px solid $line-color;



/* Footer (Meta and Site-Footer)
------------------------------------------------------------------- */

$footer-color:    		#828282;
