/* Media Queries Layout
------------------------------------------------------------------- */

#masthead.header-image div {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 16em;
}


/* Small
------------------------------------------------------------------- */

@media (max-width: $small - 1) {

  #masthead {
    font-size: $font-size-h2;
  }
  #masthead.header-image {
    height: 14em;
    margin-bottom: 1em;
  }
  #masthead.header-image div {
    height: 12em;
  }
  #pagination a:hover span.skip {
    display: none;
  }
  /* Reduce Font-Size to get more space for elements of Topbar-Navigation */
  #tnav {
    font-size: .75em;
  }
  #tnav a {
    padding: .5em 1em .5em 1em;
    border: 0;
  }
#tnav-logo > a {
  padding: .15em 1em .15em 1em;
  font-size: 1.3em;
  letter-spacing: 1px;
}


  #tnav-share a,
#tnav-search a {
  padding: .85em .5em .25em .5em;
}
  /* Hide elements in Topbar-Navigation */
  .tnav-hide {
    display: none !important;
  }
}



/* Medium
------------------------------------------------------------------- */

@media (min-width: $small ) and (max-width: $medium) {

  #masthead {
    font-size: $font-size-h1;
  }
  #masthead.header-image {
    height: 16em;
    margin-bottom: 1em;
  }
  #masthead.header-image div {
    height: 14em;
  }
}



/* Large
------------------------------------------------------------------- */

@media (min-width: $medium + 1) {

  #masthead {
    font-size: $font-size-h1;
    line-height: 1.5;
  }
  #masthead.header-image {
    height: 20em;
    margin-bottom: 1em;
  }
  #masthead.header-image div {
    height: 18em;
  }
}

