// Rename .smicon to .smicon since some filter lists of uBO  block this.
// Also needs to be changed in /_includes/helper/_social_media_icons.html
// 22.04.2020 --rw.
.smicon,
.smicon:link,
.smicon:hover,
.smicon:visited,
.smicon:active,
 {
	border: 0;
    // background: #ff0;
}
.smicon svg {
    margin-bottom: -.35em;
	height: 1.5em;
	width: 1.5em;
	fill: $grey-color;
    transition: all .15s cubic-bezier(.33,.66,.66,1);
}
.smicon:hover svg {
	fill: $ci-1;
}
#footer ul.inline-list li.smicon:before {
    content: '';
    padding: 0;
}
