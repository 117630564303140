/* Footnotes
------------------------------------------------------------------- */

.footnotes:before {
    content: "";
    position: absolute;
    height: 1px;
    width: 2em;
    margin-top: -10px;
/* Line not suitable for DfC 06.07.2020 --rw: 
    border-bottom: 1px solid  $line-color;
*/
}
.footnotes {
/*    margin-top: 4em;
 Too much margin for DfC 06.07.2020 --rw: */
    margin-top: 2em;
}
.footnotes ol {
/* Font size a bit too small for DfC 06.07.2020 --rw: 
    font-size: $font-size-small; */
    font-size: $font-size-footnotes;
}
.footnotes p {
    font-size: inherit;
    margin-bottom: 0;
}
