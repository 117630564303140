/* Table of Contents (Index)
------------------------------------------------------------------- */

// Remove margin only on frontpage - toc
.first-h2 {
  margin: 0;
}

ul.toc  {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: $header-font-family;
}

ul.toc li  {
  border-top: 1px solid $line-color;
  line-height: 1.85em;
}

body.archive ul.toc li:nth-child(5n) {
  margin-bottom: 2em;
  border-bottom: 1px solid $line-color;
}

// Special class body.dfc-ul-li currently for dfc.md only, similar to body.archive  22.10.2020 --rw: 
body.dfc-ul-li ul.toc li:nth-child(3n) {
  margin-bottom: 1em;
  border-bottom: 1px solid $line-color;
}

ul.toc li:last-child  {
  border-bottom: 1px solid $line-color;
}

// If there is a link to the article archive, don't
// show border bottom and instead a border on top
ul.toc li.archive-link  {
  border: 0;
  border-top: 1px solid $line-color;
}

ul.toc li a {
// Exclude border bottom, otherwise special lists like e. g. body.archive ul.toc li:nth-child(5n) don't work with bottom and hover bottom line, 22.10.2020 --rw: 
// was: 
//  border: 0;
// without bottom: 
  border-top: 0;
  border-left: 0;
  border-right: 0;
  display: block;
  -webkit-transition: all .15s cubic-bezier(.33,.66,.66,1);
  transition: all .15s cubic-bezier(.33,.66,.66,1);
}

/* Teaser title text, but teaser date see /_sass/04_typography/_general_typography.scss 
	--> .masthead-caption 01.12.2018 --rw:  */
ul.toc li a span {
  color: $grey-color;
  /* comment out if teaser title should always be displayed 01.12.2018 --rw:  
  display: none; */
  padding-right: .5em;
}

ul.toc li a:hover span {
  display: inline;
}

ul.toc li {
 -webkit-transition: all .15s cubic-bezier(.33,.66,.66,1);
 transition: all .15s cubic-bezier(.33,.66,.66,1);
}

ul.toc li:hover {
  background: $background-light-color;
  padding-left: .25em;
}

/* Add Jekyll Table of Contents
based on some References like: Jekyll Table of Contents, called "Wikipedia Look" in webjeda.com: 
https://blog.webjeda.com/jekyll-toc/ 
https://kramdown.gettalong.org/converter/html.html#toc
15.05.2020 --rw:  */
// Adding 'Contents' headline to the TOC
#markdown-toc::before {
/*    content: "Contents"; */
    content: "Inhalt";
    font-weight: bold;
}

#markdown-toc {
/* Colour of border 15.05.2020 --rw: 
    border: 1px solid #aaa;
*/
    border: 1px solid $ci-6;
/*     padding: 1.5em;
Change padding: top and bottom padding different from right and left padding 
15.05.2020 --rw: */
    padding: 1em 2.5em;
/* Change list-style, no defaulting to numbers, decide with * or 1., i. e. on page add 
15.05.2020 --rw: 
    list-style: decimal;
*/
    display: inline-block;
/* Change or add a different font-size for toc if necessary
15.05.2020 --rw: 
    font-size: 2em;
    font-size: 1em;
    font-size: $font-size-p; 
    font-size: $font-size-small; 
*/
}

/* Change links and link colour, e. g. no underline (implemented with border-bottom) and 
different hover colour 15.05.2020 --rw: */ 
#markdown-toc a {
//    border-bottom: 5px solid $ci-1;
    border-bottom: none;
}
#markdown-toc a:hover {
    color: darken( $ci-3, 30% );
    border-bottom: 4px solid $ci-3;
}




/* Example of a nested counter
https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Lists_and_Counters/Using_CSS_counters 
but seems difficult with ID #markdown-toc 
18.05.2020 --rw. 
*/

/* Reset inheritance from _general_typography.scss  lists, margin-left was 1.25em, but this is an unwanted margin for the entire toc 18.05.2020 --rw: */
#markdown-toc {
  margin-left: 0;
} 


