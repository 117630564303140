//
// We can still improve things by creating a placeholder––or silent class––selector.
// This way nothing gets compiled to CSS unless we actually use it.
// Placeholder selectors are defined by a %, so we’ll need to replace the class with:
//
// Use it
//
// wrap {
//   ...
//   @extend %clearfix;
// }

%clearfix {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
