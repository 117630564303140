/* Buttons
------------------------------------------------------------------- */

button,
.button,
a.button {
	color: #fff;
	background-color: $ci-1;
	border: none;
	letter-spacing: 1px;
	border-radius: 5px;
	box-shadow: 0 3px mix(#000, $ci-1, 40%);
	display: inline-block;
	text-align: center;
	line-height: 1;
	cursor: pointer;
	-webkit-appearance: none;
	transition: all 0.25s ease-out;
	vertical-align: middle;
	padding: 0.25em 1em;
	margin: 0 1em .25em 0;
}

button:hover,
.button:hover {
	background-color: mix(#fff, $ci-1, 20%);
	border: 0 !important;
}

button:active,
.button:active {
	background-color: mix(#fff, $ci-1, 20%);
	box-shadow: 0 5px $ci-1;
	transform: translateY(2px);
}


/* Color Buttons */

button.alert,
a.alert {
	background: $alert-color !important;
	box-shadow: 0 3px mix(#000, $alert-color, 40%) !important;
}
button.success,
a.success {
	background: $success-color !important;
	box-shadow: 0 3px mix(#000, $success-color, 40%) !important;
}
button.warning,
a.warning {
	background: $warning-color !important;
	box-shadow: 0 3px mix(#000, $warning-color, 40%) !important;
}
button.info,
a.info {
	background: $info-color !important;
	box-shadow: 0 3px mix(#000, $info-color, 40%) !important;
}
button.grey,
a.grey {
	background: $grey-color-dark !important;
	box-shadow: 0 3px mix(#000, $grey-color-dark, 40%) !important;
}


a.alert:hover,
button.alert:hover {
	background: mix(#000, $alert-color, 10%) !important;
}
a.success:hover,
button.success:hover {
	background: mix(#000, $success-color, 10%) !important;
}
a.warning:hover,
button.warning:hover {
	background: mix(#000, $warning-color, 10%) !important;
}
a.info:hover,
button.info:hover {
	background: mix(#000, $info-color, 10%) !important;
}
a.grey:hover,
button.grey:hover {
	background: mix(#000, $grey-color-dark, 10%) !important;
}

