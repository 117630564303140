/* Alerts
------------------------------------------------------------------- */

.alert-box {
  color: #fff;
  background-color: $grey-color-light;
  display: block;
  margin-left: 2em;
  margin-bottom: 1em;
  position: relative;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-radius: $global-radius;
}
.alert-box p {
  margin: 0;
  padding: 0 .25em;
  text-transform: none !important;
}
.alert-box a {
  color: #fff;
  border-bottom: 1px dotted #fff;
}
.alert-box a:hover {
  border-bottom: 3px solid #fff;
}

.alert-box.text,
.alert-box.text a {
    color: mix(#000, $text-color, 100%);
}

.alert-box.text a {
  border-bottom: 1px dotted $grey-color-dark;
}

.alert-box.text a:hover {
  border-bottom: 2px solid $grey-color-dark;
}

.alert-box.info {
  background: $info-color;
}
.alert-box.alert {
  background: $alert-color;
}
.alert-box.success {
  background: $success-color;
}
.alert-box.warning {
  background: $warning-color;
}
.alert-box.terminal {
  background: $grey-color-dark;
  margin-left: 0;
  color: #fff;
  font-family: $font-family-monospace;
}


.alert-box.warning::before,
.alert-box.alert::before,
.alert-box.info::before,
.alert-box.success::before {
  margin-left: -1em;
  font-size: 2em;
  line-height: .75em;
  float: left;
}

.alert-box.terminal::before {
  content: "$ ";
  color: $ci-2;
  float: left;
  margin-right: .5em;
}

.alert-box.warning::before {
  content: "!";
  color: $warning-color;
}

.alert-box.alert::before {
  content: "!";
  color: $alert-color;
}

.alert-box.info::before {
  content: "i";
  color: $info-color;
}

.alert-box.success::before {
  content: ":)";
  color: $success-color;
}

