/* Global Variables
-------------------------------------------------------------------

These settings are needed to set the breakpoints for typography
and to adjust the masthead

*/

$global-radius: 	3px;

$small:  640px;
$medium: 1024px;
$large:  1440px;

