/* Body
------------------------------------------------------------------- */

body {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $text-color;
    -webkit-text-size-adjust: 100%;
    // http://blog.typekit.com/2014/02/05/kerning-on-the-web/
    text-rendering: optimizeLegibility;
    // http://practice.typekit.com/lesson/caring-about-opentype-features/
    font-kerning: normal;
    font-variant-ligatures: common-ligatures, contextual;
    -moz-font-feature-settings: "kern", "liga", "clig", "calt";
    -ms-font-feature-settings: "kern", "liga", "clig", "alt";
    -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
    font-feature-settings: "kern", "liga", "clig", "calt";
}



/* Headlines
------------------------------------------------------------------- */

h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  font-weight: $font-weight-normal;
  padding: 0;
}

h1 {
  font-size: $font-size-h1;
/* changed 23.05.2018 --rw: 
  text-transform: uppercase; */
  font-weight: $font-weight-bold;
/*  changed 04.09.2021 --rw:
letter-spacing: 1px; */
/* changed to same margin-top as for h2 - h6 08.07.2020 --rw: 
  margin-top: 0; */
  margin-top: 1.5em;
}
h2 {
  font-size: $font-size-h2;
/* changed 23.05.2018 --rw: 
  text-transform: uppercase; */
/*  changed 04.09.2021 --rw: */ 
  font-weight: $font-weight-bold;
  letter-spacing: 1px;
/*  changed 04.09.2021 --rw: */ 
  font-style: italic;
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
// changed from 1em to 1.5em 20.05.2020 --rw: 
//  margin-top: 1em;
  margin-top: 1.5em;
// added clear to avoid h2 to start within image or image to overlap h2, respectively 23.10.2020 --rw: 
  clear:both;
}
h3 {
  font-size: $font-size-h3;
/* added font-weight-bold 29.10.2020 --rw: 
   changed 04.09.2021 --rw: 
  font-weight: $font-weight-bold; */
/*   changed 04.09.2021 --rw: */
  letter-spacing: 2px;
/*  changed 04.09.2021 --rw: 
  font-style: italic; */ 
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
// changed from 1em to 1.5em 20.05.2020 --rw: 
//  margin-top: 1em;
  margin-top: 1.5em;
}
h4 {
  font-size: $font-size-h4;
  font-weight: $font-weight-bold;
/*   changed 04.09.2021 --rw: */
  letter-spacing: 2px;
  font-style: italic;
/* changed 28.06.2018 --rw: 
  margin-top: 2em; */
// changed from 1em to 1.5em 20.05.2020 --rw: 
//  margin-top: 1em;
  margin-top: 1.5em;
}
h5 {
  font-size: $font-size-h5;
/* added to make h5 visible 05.06.2020 --rw: */
  font-weight: $font-weight-bolder;
/*  letter-spacing: 3px;
   changed 04.09.2021 --rw: */
  letter-spacing: 1px;
/*  changed 04.09.2021 --rw:  
  font-style: italic; */
/* END added to make h5 visible. */
/* Added 15.04.2021 --rw: */
  margin-top: 1em;
  margin-top: 1.5em;
/* Added 15.04.2021 --rw: */
  margin-bottom: 0.5em;
/* Added 15.04.2021 --rw: */
/*  text-decoration: underline overline wavy blue;
  text-decoration: underline overline wavy inherit;
  text-decoration: underline wavy;
  text-decoration: underline double;
  text-decoration: underline dashed; */
}
h6 {
  font-size: $font-size-h6;
/* added to make h6 visible 25.06.2020 --rw: 
  letter-spacing: 1.5px; */
  letter-spacing: 2px;
/* Changed 16.04.2021 --rw: */
  letter-spacing: 3px;
/* added to make h6 visible, or comment out if not wanted 25.06.2020 --rw: 
  font-weight: $font-weight-bolder; */
/*  changed 04.09.2021 --rw: */ 
  font-style: italic;
/* Added 15.04.2021, most decent is probably solid and a web safe grey colour --rw:
  text-decoration: underline dotted;
  text-decoration: underline solid;
  text-decoration: underline double #cccccc; */
  text-decoration: underline solid #cccccc;
/* Added 16.04.2021 --rw: */
/*  line-height: 0.5; */
  margin-top: 1em;
  margin-top: 1.25em; 
  margin-top: 1.5em;
/* Added 16.04.2021 --rw: */
  margin-bottom: 0.5em;
}

/* Consider header height when jumping to target anchor, basic idea from https://css-tricks.com/hash-tag-links-padding/ 07.07.2020 --rw: */
// Commented out because of changes in _sass/05_layout/_navigation.scss, #tnav to resolve the Problem "position: fixed;" especially with footnotes when jumping to target anchor (creation of tnav-dfc specially for dfc). 04.09.2021 --rw.
// h1::before,h2::before,h3::before,h4::before,h5::before,h6::before { 
//   display: block; 
//   content: " "; 
/* negative margin-top, same value but positive height. 100px should be ok. 07.07.2020 --rw: */
//   margin-top: -100px; 
//   height: 100px;
//   visibility: hidden; 
//   pointer-events: none;
// }

/* Same as h1::before etc. above, but for footnotes and endnotes, see e. g. [attr=value] in Attribute selectors, https://developer.mozilla.org/en-US/docs/Web/CSS/Attribute_selectors  07./11.07.2020 --rw:  */

/* 1. Footnotes 
Does not seem to work! 11.07.2020 --rw:  
NOK: [role=doc-noteref]::before {  
NOK: 
.footnote::before { 
  display: block; 
  content: " "; 
  margin-top: -100px; 
  height: 100px;
  visibility: hidden; 
  pointer-events: none;
}
*/

/* 2. Endnotes 07.07.2020, 04.11.2020; Problem: endnote no. is not offset in all browsers, trying some solutions like
https://stackoverflow.com/questions/39547773/ with: "Use inline-block and you won't face this discrepancy."
But seems problematic ...
 --rw:  */
/* Leave it away alltogether for now with // 
      w h i c h   m e a n s :   when clicking a footnote in a text the target (endnote) will not be 
      offset, so it will be too high, disappearing below a fixed page header 
04.11.2020 --rw: */ 
//[role=doc-endnote]::before { 
//  display: block; 
//  content: " "; 
/* negative margin-top, same value but positive height. 100px should be ok. 07.07.2020 --rw: */
//  margin-top: -100px; 
//  height: 100px;
//  visibility: hidden; 
//  pointer-events: none;
/* Added according to stackoverflow.com above 04.11.2020 --rw: */ 
//  position: absolute;
//}

/* Added according to stackoverflow.com above 04.11.2020 --rw: */ 
/* NOK: 
li [role=doc-endnote] { */ 
/* Leave it away alltogether for now with // 04.11.2020 (belongs to endnote above, so same here) --rw: */ 
//.footnotes li { 
//  position: relative;
//  margin-top: 25px;
/*  margin-top: 105px; */
//}



/* Customize Typography
------------------------------------------------------------------- */

p {
/* If automatic hyphenation is wanted then uncomment this 17.08.2018 --rw: 
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  -ms-word-break: break-all;
*/
  /* Non standard for webkit */
  word-break: break-word;
}



/* Images
------------------------------------------------------------------- */

img {
  border-radius: $global-radius;
  max-width: 100%;
}

.alignleft,
.left {
  float: left; 
  margin: .35em 1em 0 0;
}
.alignright,
.right {
  float: right;
  margin: .35em  0 0 1em;
}
.aligncenter,
.center {
  display: block;
  margin: .25em auto .25em;
}

figure {
  margin: 0 0 1em 0;
}
#masthead-with-background-color figure,
#masthead-with-pattern figure {
  margin: 0;
}

figcaption {
/*  color: $grey-color-light; 
02.06.2020 --rw: 
*/
  color: $grey-color;
  font-size: $font-size-small;
  padding-top: .25em;
  text-align: right;

  a, a:link,
  .masthead-caption a, .masthead-caption a:link {
    border-bottom: 1px dotted $grey-color-light;
    color: $grey-color;
  }

  a:hover,
  .masthead-caption a:hover {
    border-bottom-style: solid;
  }
}

.masthead-caption {
  padding-right: .5em;
  text-align: right;
  /* Teaser-date, but teaser title text see /_sass/05_layout/_toc.scss 
  	  -> ul.toc li a span 01.12.2018 --rw:   
  color: $grey-color-light; */
  color: $grey-color; 
  font-size: $font-size-small;
  padding-top: .25em;
}

/* added for digi-oek.ch similar to .masthead-caption or figcaption, 
which is currently being used in /_includes/name_1 and this
is fetched within /_layouts/frontpage.html 
(i. e. displayed on main frontpage only) 
For useage of sitetitle01 see /_includes/name_1 (i. e. name/title of site
such as Forschungsstelle Digitale Ökonomie ...)
02.12.2018/22.10.2020 --rw: */
.sitetitle01 {
  padding-right: .5em;
  text-align: right;
  color: $grey-color; 
  font-size: $font-size-small;
  padding-top: .25em;
}



/* Tables
------------------------------------------------------------------- */

td {
  vertical-align: top;
}

/* Some table styling, starting here 19.05.2020 --rw: 
   --------------------------------------------------
   This following table seems to be default according to some sources, thus no need to activate: 
table {
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: gray;
} */

table, th, td {
  border: 1px solid gray;
  padding: 0.25em;
} 

thead {
  background: #f0ffff; /* Azure mist, https://www.colorhexa.com/f0ffff */
//  font-weight: 100; 
//  font-weight: lighter; 
//  color: red; 
//  font-style: italic;
  text-align: left;
}

tfoot {
  /* background: #f0f8ff; Alice blue, https://www.colorhexa.com/f0f8ff */
  background: #fff8f0; /* Split Complementary Color from Azure mist in thead, https://www.colorhexa.com/fff8f0 */ 
//  font-weight: 100;
//  font-weight: lighter;
}

table caption {
  text-align: left;
  font-weight: bold;
  color: #08457e; /* Dark cerulean, https://www.colorhexa.com/08457e */
//  background: #f0ffff; /* Azure mist, https://www.colorhexa.com/f0ffff */
//  background: #f0fff0; /* Honeydew: Tetradic Color of Azure mist, https://www.colorhexa.com/f0fff0 */
  background: #fff0f0; /* Split Complementary Color from Azure mist, https://www.colorhexa.com/fff0f0 */ 
//  border: 1px solid gray;
// alternatively: border: solid gray; plus border-width (or fully shorthand): 
//  border: solid gray; 
  border-style: solid;
  border-color: gray;
  /* 3-value syntax: first value: top edge, second left and right, third bottom edge; 
     rw: bottom only 1px because FF seems to add this to the top border of the tabel which is 1px */
  border-width: 2px 2px 1px;
}

/* Class .dfc 04.06.2020 --rw: */

/* Smaller font size works for entire table 23.06.2020 --rw: 
.dfc {
  font-size: smaller; 
}
 */

.dfc thead {
//  background: #f0ffff; /* Azure mist, https://www.colorhexa.com/f0ffff */
  background: #bcd4e6; /* Beau blue/Pale aqua, https://www.colorhexa.com/bcd4e6 */
  border: 1px solid red;
//  font-weight: 100; 
//  font-weight: lighter; 
//  color: red; 
//  font-style: italic;
  text-align: left;
}

.dfc tfoot {
//  background: #f0f8ff; /* Alice blue, https://www.colorhexa.com/f0f8ff */
//  background: #e6cebc; /* Complementary Color from Beau blue/Pale aqua in thead, https://www.colorhexa.com/e6cebc */
  background: #e6e3bc; /* Split Complementary Color from Beau blue/Pale aqua in thead, https://www.colorhexa.com/e6cebc */  
//  font-weight: 100;
//  font-weight: lighter;
}


/* Code
------------------------------------------------------------------- */

pre {
  font-size: .75em;
  font-family: $font-family-monospace;
  font-weight: normal;
  overflow: auto;
  padding: 5px;
  border-radius: $global-radius;
}
pre code {
  border: 0;
}

code {
  font-family: $font-family-monospace;
  line-height: 1.5;
}

p code {
  background: $ci-4;
  box-decoration-break: clone;
  padding: 0 .25em;
}



/* Lists
------------------------------------------------------------------- */

ul, ol {
  margin-left: 1.25em;
  padding: 0;
/* Reduce line height of lists 05.10.2022 */
  line-height: 1.0;
  line-height: 1.2;
}
li {
  margin-left: 0;
}

.no-bullet {
  list-style: none;
  margin-left: 0;
}

li {
  > ul,
  > ol {
   margin-bottom: 0;
 }
}

dl {

}
dt:first-child {
  padding-top: 0px;
}
dt {
  font-weight: bold;
/* padding-top is too much padding 23.06.2020 --rw:
  padding-top: 30px;  */
  padding-top: 20px;  
}
dd {
}
article dl dt   { line-height: 1.3; }
article dl dd   { line-height: 1.6; margin-bottom: rem-calc(12); margin-left: rem-calc(24); }



/* Quotes
------------------------------------------------------------------- */

blockquote {
/* 
Margin top is too large, reduce to 1 em 29.10.2020 --rw: 
  margin: 2em 0em 2em 2em;
*/
  margin: 1em 0em 2em 2em;
/* no uppercase 23.04.2020 --rw: 
  text-transform: uppercase;
*/ 
  color: $ci-2;
  font-style: italic;
  p {
    font-style: italic;
  }
  a {
    color: $ci-2 !important;
    border-color: $ci-2 !important;
  }
  cite:before {
    content:"\2014 \0020";
    margin-left: .25em;
  }
}
cite {
  padding-top: 5px;
}
.frontpage blockquote {
  margin: 0;
}

button, .button {
  letter-spacing: 1px;
}

mark {
  background-color: mix(#fff, $warning-color, 50%);
  box-decoration-break: clone;
  padding: 0 .5em;
}
kbd {
  background: $background-light-color;
  border-radius: $global-radius;
  padding:0 0.25em;
}


/* Typography
------------------------------------------------------------------- */

hr {
  border: 0;
  border-top: dotted 1px  $line-color;
}

// Break with clear attribute (br clear =)  is deprecated in HTML5, use CSS to ensure that a line break appearing in text wrapped around an image causing the next bit of content after the <br> to appear below the image. 09.11.2020 --rw: 

br.clear {
  clear: both; 
}


// The ::selection CSS pseudo-element applies rules to the portion of
// a document that has been highlighted by the user.

::selection {
  background: $ci-4;
}
