/* Panel
------------------------------------------------------------------- */

.border-dotted {
  border: 1px dotted $grey-color;
  padding: 1em;
  border-radius: $global-radius;
}


/* Colors
------------------------------------------------------------------- */

.ci-1 { color: $ci-1; }
.ci-2 { color: $ci-2; }
.ci-3 { color: $ci-3; }
.ci-4 { color: $ci-4; }

.ci-1-bg { background: $ci-1; }
.ci-2-bg { background: $ci-2; }
.ci-3-bg { background: $ci-3; }
.ci-4-bg { background: $ci-4; }

.alert-color { color: $alert-color; }
.success-color { color: $success-color; }
.warning-color { color: $warning-color; }
.info-color { color: $info-color; }

.alert-color-bg { background: $alert-color; }
.success-color-bg { background: $success-color; }
.warning-color-bg { background: $warning-color; }
.info-color-bg { background: $info-color; }


.grey { color: $grey-color; }
.grey-light { color: $grey-color-light; }
.grey-dark { color: $grey-color-dark; }


/* CSS-Classes to add margin at top or bottom
------------------------------------------------------------------- */

.tem1 { margin-top: 1em !important; }
/* .tem2 { margin-top: 2em !important; }  
Changed 28.06.2018 --rw: */ 
.tem2 { margin-top: 0.2em !important; }
.tem3 { margin-top: 3em !important; }
.tem4 { margin-top: 4em !important; }

.bem1 { margin-bottom: 1em !important; }
/* .bem2 { margin-bottom: 2em !important; }
Changed 28.06.2018 --rw: */
.bem2 { margin-bottom: 0.2em !important; }
.bem3 { margin-bottom: 3em !important; }
.bem4 { margin-bottom: 4em !important; }

