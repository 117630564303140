/* Navigation

There is a class called .tnav-hide to hide elements on smaller
devices. You find it in _sass/_layout/_layout_media_queries.scss

------------------------------------------------------------------- */

#tnav {
  font-size: 18px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
/* position fixed: makes the top horizontal area stay, well, fixed, and the opacity should make footnotes (after clicking on a footnote reference link) still appear and be readable. But even with opacity lowered to 0.7 or lower this is not a good solution: Trade-off between readability of what's in the area (here: tnav-logo with name of organisation), which should be readable all the time, versus the footnote, which should be readable when clicking on a footnote. 
Chosen solution: make a special tnav without "position: fixed" for DfC pages which are known to have more footnotes than other pages. 
I. e. tnav-dfc is for DfC pages (via _includes/helper/_2_topbar_navigation-spez-dfc.html and _layouts/default-spez-dfc.html), tnav is for everything else. 
19.08.2021 --rw :*/
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  white-space: nowrap;
  border-bottom: 1px solid $line-color;
  background: $background-color;
/* Lower opacity [11.2020 (?)] --rw:  
  opacity: .9; */ 
  opacity: 0.7;
}

#tnav-dfc {
  font-size: 18px;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
/* position fixed: makes the top horizontal area stay, well, fixed, and the opacity should make footnotes (after clicking on a footnote reference link) still appear and be readable. But even with opacity lowered to 0.7 or lower this is not a good solution: Trade-off between readability of what's in the area (here: tnav-logo with name of organisation), which should be readable all the time, versus the footnote, which should be readable when clicking on a footnote. 
Chosen solution: make a special tnav without "position: fixed" for DfC pages which are known to have more footnotes than other pages. 
I. e. tnav-dfc is for DfC pages (via _includes/helper/_2_topbar_navigation-spez-dfc.html and _layouts/default-spez-dfc.html), tnav is for everything else. 
IMPORTANT: see also 
	#tnav li, #tnav-dfc li 
	#tnav a, #tnav-dfc a 
	#tnav a:hover, #tnav-dfc a:hover 
below where #tnav-dfc needed to be added!
19.08.2021 --rw :*/
/*
  position: fixed;
*/
  left: 0;
  top: 0;
  z-index: 3;
  white-space: nowrap;
  border-bottom: 1px solid $line-color;
  background: $background-color;
/* Lower opacity [11.2020 (?)] --rw:  
  opacity: .9; */ 
/* original opacity: 0.9 for tnav-dfc: 
  opacity: 0.7; */
  opacity: 0.9; 
}

.tnav-list,
.tnav-item {
  position: relative;
  height: 3em;
}
.tnav-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.tnav-item {
  padding: 0;
  margin: 0;
  height: 100%;
}
/* Add tnav-dfc, see explanation at #tnav 19.08.2021 --rw: */
#tnav li, #tnav-dfc li {
  line-height: 2;
}
/* Add tnav-dfc, see explanation at #tnav 19.08.2021 --rw: */
#tnav a, #tnav-dfc a {
  display: block;
  text-decoration: none;
  white-space: nowrap;
  padding: .5em 2em .5em 2em;
  border: 0;
}
/* Add tnav-dfc, see explanation at #tnav 19.08.2021 --rw: */
#tnav a:hover, #tnav-dfc a:hover {
  border: 0;
  color: $ci-2;
/* Cince ci-1 in /_sass/01_colors/_color_scheme_simplicity.scss, ci-1 is too dark, not enough contrast; thus change to newly created ci-6. 20.07.2018 --rw:  
  background: $ci-1; */
  background: $ci-6; 
}
.tnav-list {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
}
/* Initial menus should be inline-block so that they are horizontal */
.tnav-horizontal .tnav-item,
.tnav-horizontal .tnav-heading,
.tnav-horizontal .tnav-separator {
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: middle;
}
/* Submenus should still be display: block; */
.tnav-item .tnav-item {
  display: block;
}
.tnav-children {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  margin: 0;
  padding: 0;
  z-index: 3;
/* New for digi-oek.ch, background color, see 
	/_sass/01_colors/_color_scheme_simplicity.scss > $bgcolor 
		01.12.2018 --rw:    
background: $line-color; 
*/
background: $bgcolor;
}
.tnav-horizontal .tnav-children {
  left: 0;
  top: auto;
  width: inherit;
}
.tnav-allow-hover:hover > .tnav-children,
.tnav-active > .tnav-children {
  display: block;
  position: absolute;
}

/* Vertical Menus - show the dropdown arrow */
.tnav-has-children > .tnav-link:after {
  padding-left: 0.5em;
  content: "\25B8";
  font-size: small;
}

/* Horizontal Menus - show the dropdown arrow */
.tnav-horizontal .tnav-has-children > a:after {
  content: " \25BE";
}

/* TNAV Images – Reduce Size of Images */
#tnav-logo > a {
  padding: 0 1em 0 1em;
  /* for digi-oek.ch: should be smaller 01.12.2018 --rw: 
  font-size: 1.5em; */ 
  font-size: 1em; 
  letter-spacing: 1px;
}

/* TNAV Images – Reduce Size of Images --rw: strange above, so added this 11.07.2018 --rw: */
#tnav-logo > a img {
  height: 50px;
}

/* Use this class to hide the dropdown arrow for menus (with an icon – see share icon) */
.no-dropdown-arrow > a:after {
  content: "" !important;
}
#tnav-search,
#tnav-share,
#tnav-menu {
  float: right;
}
#tnav-menu > a {
  color: $line-color;
}
#tnav-share a,
#tnav-search a {
  display: block;
  line-height: 1.5;
  padding: .65em 1em .5em 1em;
}
#tnav-share svg,
#tnav-search svg {
  width: 1.5em;
  height: 1.5em;
  fill: $line-color;
}
