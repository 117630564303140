
.highlight {
    border-top: 1px solid  $line-color;
    border-bottom: 1px solid  $line-color;
    background-color: $background-color;
    color: $text-color;

    span {
        font-weight: normal;
        font-family: $font-family-monospace;
    }

    .c { color: $background-light-color; } /* Comment */
    .err { color: $text-color; } /* Error */
    .g { color: $text-color; } /* Generic */
    .k { color: $success-color; } /* Keyword */
    .l { color: $text-color; } /* Literal */
    .n { color: $text-color; } /* Name */
    .o { color: $success-color; } /* Operator */
    .x { color: $warning-color; } /* Other */
    .p { color: $text-color; } /* Punctuation */
    .cm { color: $background-light-color; } /* Comment.Multiline */
    .cp { color: $success-color; } /* Comment.Preproc */
    .c1 { color: $background-light-color; } /* Comment.Single */
    .cs { color: $success-color; } /* Comment.Special */
    .gd { color: $ci-2; } /* Generic.Deleted */
    .ge { color: $text-color;; font-style: italic } /* Generic.Emph */
    .gr { color: $alert-color; } /* Generic.Error */
    .gh { color: $warning-color; } /* Generic.Heading */
    .gi { color: $success-color; } /* Generic.Inserted */
    .go { color: $text-color; } /* Generic.Output */
    .gp { color: $text-color; } /* Generic.Prompt */
    .gs { color: $text-color;; font-weight: bold } /* Generic.Strong */
    .gu { color: $warning-color; } /* Generic.Subheading */
    .gt { color: $text-color; } /* Generic.Traceback */
    .kc { color: $warning-color; } /* Keyword.Constant */
    .kd { color: $ci-1; } /* Keyword.Declaration */
    .kn { color: $success-color; } /* Keyword.Namespace */
    .kp { color: $success-color; } /* Keyword.Pseudo */
    .kr { color: $ci-1; } /* Keyword.Reserved */
    .kt { color: $alert-color; } /* Keyword.Type */
    .ld { color: $text-color; } /* Literal.Date */
    .m { color: $ci-2; } /* Literal.Number */
    .s { color: $ci-2; } /* Literal.String */
    .na { color: $text-color; } /* Name.Attribute */
    .nb { color: $warning-color } /* Name.Builtin */
    .nc { color: $ci-1; } /* Name.Class */
    .no { color: $warning-color; } /* Name.Constant */
    .nd { color: $ci-1; } /* Name.Decorator */
    .ni { color: $warning-color; } /* Name.Entity */
    .ne { color: $warning-color; } /* Name.Exception */
    .nf { color: $ci-1; } /* Name.Function */
    .nl { color: $text-color; } /* Name.Label */
    .nn { color: $text-color; } /* Name.Namespace */
    .nx { color: $text-color; } /* Name.Other */
    .py { color: $text-color; } /* Name.Property */
    .nt { color: $ci-1; } /* Name.Tag */
    .nv { color: $ci-1; } /* Name.Variable */
    .ow { color: $success-color; } /* Operator.Word */
    .w  { color: $text-color; } /* Text.Whitespace */
    .mf { color: $ci-2; } /* Literal.Number.Float */
    .mh { color: $ci-2; } /* Literal.Number.Hex */
    .mi { color: $ci-2; } /* Literal.Number.Integer */
    .mo { color: $ci-2; } /* Literal.Number.Oct */
    .sb { color: $background-light-color } /* Literal.String.Backtick */
    .sc { color: $ci-2; } /* Literal.String.Char */
    .sd { color: $text-color; } /* Literal.String.Doc */
    .s2 { color: $ci-2; } /* Literal.String.Double */
    .se { color: $warning-color; } /* Literal.String.Escape */
    .sh { color: $text-color; } /* Literal.String.Heredoc */
    .si { color: $ci-2; } /* Literal.String.Interpol */
    .sx { color: $ci-2; } /* Literal.String.Other */
    .sr { color: $alert-color; } /* Literal.String.Regex */
    .s1 { color: $ci-2; } /* Literal.String.Single */
    .ss { color: $ci-2; } /* Literal.String.Symbol */
    .bp { color: $ci-1; } /* Name.Builtin.Pseudo */
    .vc { color: $ci-1; } /* Name.Variable.Class */
    .vg { color: $ci-1; } /* Name.Variable.Global */
    .vi { color: $ci-1; } /* Name.Variable.Instance */
    .il { color: $ci-2; } /* Literal.Number.Integer.Long */
}