/* Modular Scale 1em
   You can use different modular scales with *Simplicity*
   Just configure the modular scale in _config.yml you want to use.

   => Currently set in _config.yml: modular_scale: modular_scale_1em 02.12.2018 --rw.

   => See   a l s o   ./assets/css/simplicity.scss which also defines some $-variables, 
	which seems a bit inconsistent (perhaps there is a reason): 
	- Font Families
	- Line-height
	- Font Weight 
	20.05.2020 --rw.

   Font Sizes
------------------------------------------------------------------- */

/* changed for digi-oek.ch 01.12.2018 --rw: 
$base-font-size                 : 18px; */
$base-font-size                 : 14px; 
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-medium-screen   : 22px; */
$base-font-size-medium-screen   : 16px;
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-large-screen    : 26px; */ 
$base-font-size-large-screen    : 20px;
$font-size-p                    : 1em;
/* changed 23.05.2018 --rw:
$font-size-h1                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h1                   : 1.424em; */
$font-size-h1                   : 1.424em; 
/* changed 23.05.2018 --rw:
$font-size-h2                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h2                   : 1.266em; */
$font-size-h2                   : 1.424em;
/* changed 23.05.2018 --rw:
$font-size-h3                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h3                   : 1.125em; */
$font-size-h3                   : 1.2em;
$font-size-h4                   : 1em;
$font-size-h5                   : 1em;
/* font-size-h6 is also used for line-height (!) of #footer in _sass/05_layout/_footer.scss */
$font-size-h6                   : 1em;
/* changed 15.05.2018 --rw: 
$font-size-small                : 1em; */
$font-size-small                : .75em; 
/* new for footnotes 06.07.2020 --rw: */
$font-size-footnotes            : .85em; 



/* Change 15.04.2021: Base siez h6 1em, then factor 1.1 for every step, i. e. h6: 1.0, h5: 1.0 * 1.1 = 1.1, h4: 1.1 * 1.1 = 1.21, h3: 1.21 * 1.1 = 1.331 etc.  */ 


/* changed for digi-oek.ch 01.12.2018 --rw: 
$base-font-size                 : 18px; */
$base-font-size                 : 14px; 
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-medium-screen   : 22px; */
$base-font-size-medium-screen   : 16px;
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-large-screen    : 26px; */ 
$base-font-size-large-screen    : 20px;
$font-size-p                    : 1em;
/* changed 23.05.2018 --rw:
$font-size-h1                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h1                   : 1.424em; */
$font-size-h1                   : 1.424em; 
$font-size-h1                   : 1.464em; 
/* changed 23.05.2018 --rw:
$font-size-h2                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h2                   : 1.266em; */
$font-size-h2                   : 1.424em;
$font-size-h2                   : 1.464em; 
/* changed 23.05.2018 --rw:
$font-size-h3                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h3                   : 1.125em; */
$font-size-h3                   : 1.2em;
$font-size-h3                   : 1.331em;
$font-size-h4                   : 1em;
$font-size-h4                   : 1.21em;
$font-size-h5                   : 1em;
$font-size-h5                   : 1.1em;
/* font-size-h6 is also used for line-height (!) of #footer in _sass/05_layout/_footer.scss */
$font-size-h6                   : 1em;
/* changed 15.05.2018 --rw: 
$font-size-small                : 1em; */
$font-size-small                : .75em; 
/* new for footnotes 06.07.2020 --rw: */
$font-size-footnotes            : .85em; 



/* Change 04.09.2021: Base siez h6 1em, then factor 1.15 for every step, i. e. 
h6: 1.0, 
h5: 1.0 * 1.15 = 1.15, 
h4: 1.15 * 1.15 = 1.323, 
h3: 1.3225 * 1.15 = 1.520, 
h2: 1.520875 * 1.15 =  1.749
h1: 1.74900625 * 1.15 = 2.011 */ 


/* changed for digi-oek.ch 01.12.2018 --rw: 
$base-font-size                 : 18px; */
$base-font-size                 : 14px; 
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-medium-screen   : 22px; */
$base-font-size-medium-screen   : 16px;
/* changed for digi-oek.ch 01.12.2018 --rw:
$base-font-size-large-screen    : 26px; */ 
$base-font-size-large-screen    : 20px;
$font-size-p                    : 1em;
/* changed 23.05.2018 --rw:
$font-size-h1                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h1                   : 1.424em; */
$font-size-h1                   : 1.424em; 
$font-size-h1                   : 1.464em;
$font-size-h1                   : 1.685em;  
/* changed 23.05.2018 --rw:
$font-size-h2                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h2                   : 1.266em; */
$font-size-h2                   : 1.424em;
$font-size-h2                   : 1.464em; 
$font-size-h2                   : 1.518em; 
/* changed 23.05.2018 --rw:
$font-size-h3                   : 1em; 
changed 29.10.2020 --rw:
$font-size-h3                   : 1.125em; */
$font-size-h3                   : 1.2em;
$font-size-h3                   : 1.331em;
$font-size-h3                   : 1.368em;
$font-size-h4                   : 1em;
$font-size-h4                   : 1.21em;
$font-size-h4                   : 1.231em;
$font-size-h5                   : 1em;
$font-size-h5                   : 1.1em;
$font-size-h5                   : 1.11em;
/* font-size-h6 is also used for line-height (!) of #footer in _sass/05_layout/_footer.scss */
$font-size-h6                   : 1em;
/* changed 15.05.2018 --rw: 
$font-size-small                : 1em; */
$font-size-small                : .75em; 
/* new for footnotes 06.07.2020 --rw: */
$font-size-footnotes            : .85em; 

