/* Jump to top
------------------------------------------------------------------- */

/* up-to-top 
   Rename up-to-top  to  since some filter lists of uBO  block this, 
   e. g. Fanboy’s Annoyance.
   Also needs to be changed in /_includes/helper/_4_footer.html
   where it originally was: 
      <li id="up-to-top"><a href="#top-of-page">›</a></li>
   Note: #top-of-page was changed earlier, but id=up-to-top needs to be changed, too.
   Therefore, the CSS selector in this file #up-to-top obviously has to be renamed. 
   04.05.2022 --rw.
*/

#spec-u {
	float: right;
}
#spec-u a {
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	display: block;
	padding: 0 .5em 0 .5em;
}
li#spec-u {
	display: block;
	padding: 0 .2em 0 0;
	border-radius: $global-radius;
}
li#spec-u:hover {
	background: $ci-1;
	color: #fff;
}
#spec-u a:hover {
	color: #fff;
}
#footer ul.inline-list li#spec-u:before {
	content: '';
	padding: 0;
}
