/* Search
------------------------------------------------------------------- */

#search-container input {
  border: 0;
  border-bottom: 1px solid $ci-1;
  outline:none;
  color: $ci-1;
  background: mix(#fff, $ci-1, 95%);
  width: 100%;
}

#search-container .search-input {
	width: 55%;
	margin-right: 1em;
	padding: 0.25em .25em;
}

#search-container .search-button {

}

#search-container input:hover,
#search-container input:active,
#search-container input:visited,
#search-container input:focus,
#search-container input:hover:focus,
#search-container input:hover:active {
  border-bottom: 3px solid $ci-1;
  background: transparent;
}


div.class.alert-box.text div#search-container input#search-input {
	background: transparent !important;
}