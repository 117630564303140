/* Links
------------------------------------------------------------------- */

a,
a:link {
    -webkit-transition: all .15s cubic-bezier(.33,.66,.66,1);
    transition: all .15s cubic-bezier(.33,.66,.66,1);
    color: $ci-1;
    text-decoration: none;
/* Change width of border-bottom 15.05.2020 --rw:
    border-bottom: 2px solid $ci-1;  */
    border-bottom: 1px solid $ci-1;
}

a:visited {
    border-bottom: $background-light-color;
}

a:hover {
    color: darken( $ci-1, 10% );
    border-bottom: 4px solid $ci-1;
}

a:focus {
    color: lighten( $ci-1, 20% );
}

a:active {
    color: darken( $ci-1, 20% );
}

h1 a, h1 a:visited, h1 a:link,
h2 a, h2 a:visited, h2 a:link,
h3 a, h3 a:visited, h3 a:link,
h4 a, h4 a:visited, h4 a:link,
h5 a, h5 a:visited, h5 a:link,
h6 a, h6 a:visited, h6 a:link
{
    border: 0;
}


a a:visited,
a:link img,
a:hover img,
a:focus img,
a:active img,
a img { border: 0; }

a img { border: none; }

#content a img  {
    display: block; 
    border: 0;
    -webkit-transition: all .15s cubic-bezier(.33,.66,.66,1);
    transition: all .15s cubic-bezier(.33,.66,.66,1);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000px;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}

#content a img:hover {
    box-shadow: -2px 0 2px 0 rgba(0,0,0,0.16) , 2px 0 2px 0 rgba(0,0,0,0.16) , 0 2px 2px 0 rgba(0,0,0,0.23);
    -webkit-transform: translate3d(0,-3px,0);
    transform: translate3d(0,-3px,0);
}


/* added for tag cloud 13.07.2018 --rw:  */
.tag, 
a.tag, 
a.tag:link {
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;
}

/* Classes tagclouditem99 for automatic tag-item sizes of a tag cloud in context with /_includes/tagcloud.html rather than inline style forbidden by strict CSP. 
Added 25.09.2018 --rw: */ 

a.tagclouditem1,
a.tagclouditem1:link {
    white-space: nowrap; 
    font-size: 1.1em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
a.tagclouditem2,
a.tagclouditem2:link {
    white-space: nowrap; 
    font-size: 1.2em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
a.tagclouditem3,
a.tagclouditem3:link {
    white-space: nowrap; 
    font-size: 1.3em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
a.tagclouditem4, 
a.tagclouditem4:link {
    white-space: nowrap; 
    font-size: 1.4em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
.tagclouditem5, 
.tagclouditem5:link {
    white-space: nowrap; 
    font-size: 1.5em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
.tagclouditem6,
.tagclouditem6:link {
    white-space: nowrap; 
    font-size: 1.6em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
.tagclouditem7, 
.tagclouditem7:link {
    white-space: nowrap; 
    font-size: 1.7em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: */
    padding-left: 1.8em;
    padding-top: 0.2em;	
}
.tagclouditem8, 
.tagclouditem8:link {
    white-space: nowrap; 
    font-size: 1.8em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: 
       NOTE: padding-left now again very low value, otherwise it won't fit 25.09.2018 --rw: */
    padding-left: 0.1em;
    padding-top: 0.2em;	
}
.tagclouditem9, 
.tagclouditem9:link {
    white-space: nowrap; 
    font-size: 1.9em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: 
       NOTE: padding-left now again very low value, otherwise it won't fit 25.09.2018 --rw: */
    padding-left: 0.2em;
    padding-top: 0.2em;	
}

.tagclouditem10, 
.tagclouditem10:link {
    white-space: nowrap; 
    font-size: 2.0em;
    color: $ci-5;
    /* text-decoration: none; */
    border-bottom: none;
    /* padding: 0.7em; */
    /* padding-left and -top seems better to actually click on the right link 20.07.2018 --rw: 
       NOTE: padding-left now again very low value, otherwise it won't fit 25.09.2018 --rw: */
    padding-left: 0.3em;
    padding-top: 0.2em;	
}
